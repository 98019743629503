
.btn {
    font-size: 14px;
    font-weight: 500;
    padding: 16px 40px 16px;
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    border-width: 2px;
    border-style: solid;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0 0 10px 0;
    text-align: center;
    border-color: #8b002d;
    background-color: #8b002d;
}

.btn:hover,
.btn:focus,
.btn:active{
    border-color: #8b002d;
    background-color: #fcfcfc;
    color: #8b002d;
}

.btn-primary {
    --bs-btn-active-color: #8b002d;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #8b002d;
}

.footer {

    background-color: #8b002d;
    color: #fff;
}

.footerNav ul li {
    display: inline-block;
    list-style: none;
    padding-right: 10px;
}

.footerNav ul li a {
    color: #fff;
}
